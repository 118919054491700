












import { Component, Vue } from "vue-property-decorator";
import { RouterNames } from "@/router/routernames";
import { BaseDto } from "@/shared/dtos/base-dto";
import { UtilsString } from "@/utils/utils-string";
@Component({
  components: {
    DynamicTable: () => import("@/views/dynamic_tables/dynamic_table.vue"),
  },
})
export default class RecursosLista extends Vue {
  editar(obj: BaseDto) {
    this.abrir_formulario(UtilsString.ValueOf(obj.id));
  }

  abrir_formulario(id: string) {
    this.$router.push({
      name: RouterNames.recursosformulario,
      params: { id: id },
    });
  }
}
